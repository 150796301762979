//import atom from "../images/atomic-loading-green.png";
import React from "react";
import loadGif from "../images/pablita-173.gif";

const Loader = () => {
  return (
    <div className="w-full h-[100vh] bg-[#47a3b3] flex justify-center items-center z-100 fixed top-0 left-0">
      <div className="w-[200px] h-[200px] rounded-full shadow-xl bg-gradient-to-l from-[#47a3b3] border border-violet-500 text-center flex flex-col justify-center items-center">
        <img alt="loader" src={loadGif} className="w-[70px] h-[70px]" />
      </div>
    </div>
  );
};

export default Loader;
