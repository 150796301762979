/* eslint-disable */
import { signOut } from "firebase/auth";
import { collection, deleteDoc, doc, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { auth, db } from "../Utils/Firebase";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  // to navigate within app
  const navigate = useNavigate();

  // for user login confirmation
  const [user, setuser] = useState(localStorage.getItem("isLoggedIn"));

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setuser(authUser);
      } else {
        setuser(null);
      }
    });
  }, []);

  //   logging out user
  const handleLogout = () => {
    signOut(auth).then(() => {
      localStorage.setItem("isLoggedIn", false);
      setuser(null);
      navigate("/");
      return toast.error("You've successfully Logged out");
    });
  };

  //   this is for the loader

  const [loader, setloader] = useState(false);

  // Error Notification
  const [notification, notificationF] = useState("");

  useEffect(() => {
    const timeout = setTimeout(() => {
      notificationF("");
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [notification]);

  // Blog

  const bloginitialState = {
    title: "",
    description: "",
  };

  // get blog from firebase
  const [blogsFromDB, blogsFromDBF] = useState([]);

  useEffect(() => {
    // setloader(true);
    const unsub = onSnapshot(
      collection(db, "Blog"),

      (snapshot) => {
        let list = [];

        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });

        if (!list || list.length === 0) {
          // setloader(true);
          localStorage.setItem("blog", JSON.stringify([]));
        } else {
          blogsFromDBF(list);
          localStorage.setItem("blog", JSON.stringify(list));
          setloader(false);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  // this is to arrange the blog in order i.e 1, 2, 3
  const blogsFromDBArranged = blogsFromDB;
  blogsFromDBArranged?.sort(function (a, b) {
    return b.dateId - a.dateId;
  });

  const handleDeleteBlog = async (id) => {
    if (window.confirm("Are you sure you want to delete this blog?")) {
      try {
        // setloader(true);
        await deleteDoc(doc(db, "Blog", id));
        setloader(false);
        window.location.reload();
        toast.error("Blog successfully deleted");
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Resoures

  const resourceinitialState = {
    resourceTitle: "",
    author: "",
    readtime: "",
    resourceDescription: "",
    resourceLink: "",
  };

  // get resource from firebase
  const [resourcesFromDB, resourcesFromDBF] = useState([]);

  useEffect(() => {
    // setloader(true);
    const unsub = onSnapshot(
      collection(db, "Resource"),

      (snapshot) => {
        let list = [];

        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });

        if (!list || list.length === 0) {
          // setloader(true);
          localStorage.setItem("resource", JSON.stringify([]));
        } else {
          resourcesFromDBF(list);
          console.log(list);
          localStorage.setItem("resource", JSON.stringify(list));
          setloader(false);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  // this is to arrange the resource in order i.e 1, 2, 3
  const resourcesFromDBArranged = resourcesFromDB;
  resourcesFromDBArranged?.sort(function (a, b) {
    return b.dateId - a.dateId;
  });

  const handleDeleteResource = async (id) => {
    if (window.confirm("Are you sure you want to delete this resource?")) {
      try {
        // setloader(true);
        await deleteDoc(doc(db, "Resource", id));
        setloader(false);
        window.location.reload();
        toast.error("Resource successfully deleted");
      } catch (error) {
        console.log(error);
      }
    }
  };

  // get Book from firebase
  const [booksFromDB, booksFromDBF] = useState([]);

  useEffect(() => {
    setloader(true);
    const unsub = onSnapshot(
      collection(db, "Books"),

      (snapshot) => {
        let list = [];

        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });

        if (!list || list.length === 0) {
          // setloader(true);
          localStorage.setItem("Book", JSON.stringify([]));
        } else {
          booksFromDBF(list);
          localStorage.setItem("Book", JSON.stringify(list));

          setloader(false);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  // this is to arrange the book in order i.e 1, 2, 3
  const booksFromDBArranged = booksFromDB;
  booksFromDBArranged?.sort(function (a, b) {
    return b.dateId - a.dateId;
  });

  const handleDeleteBook = async (id) => {
    if (window.confirm("Are you sure you want to delete this Book?")) {
      try {
        // setloader(true);
        await deleteDoc(doc(db, "Books", id));
        setloader(false);
        window.location.reload();
        toast.error("Book successfully deleted");
      } catch (error) {
        console.log(error);
      }
    }
  };

  // get Podcast from firebase
  const [PodcastFromDB, PodcastFromDBF] = useState([]);

  useEffect(() => {
    // setloader(true);
    const unsub = onSnapshot(
      collection(db, "Podcast"),

      (snapshot) => {
        let list = [];

        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });

        if (!list || list.length === 0) {
          localStorage.setItem("podcast", JSON.stringify([]));
        } else {
          PodcastFromDBF(list);
          localStorage.setItem("podcast", JSON.stringify(list));

          setloader(false);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  // this is to arrange the Podcast in order i.e 1, 2, 3
  const PodcastFromDBArranged = PodcastFromDB;
  PodcastFromDBArranged?.sort(function (a, b) {
    return b.dateId - a.dateId;
  });

  const handleDeletePodcast = async (id) => {
    if (window.confirm("Are you sure you want to delete this Podcast?")) {
      try {
        // setloader(true);
        await deleteDoc(doc(db, "Podcast", id));
        setloader(false);
        window.location.reload();
        toast.error("Podcast successfully deleted");
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Sypnosis

  const initialmainSypnosisState = {
    SypnosisBookTitle: "",
    SypnosisBookTextSection: "",
  };
  // getting Sypnosis  info from Firebase

  const [SypnosisState, SypnosisStateF] = useState([]);

  useEffect(() => {
    // setloader(true);
    const unsub = onSnapshot(
      collection(db, "Sypnosis"),

      (snapshot) => {
        let list;

        snapshot.docs.forEach((doc) => {
          list = { ...doc.data() };
        });

        if (!list || list.length === 0) {
          localStorage.setItem("Sypnosis", JSON.stringify({}));
        } else {
          SypnosisStateF(list);
          localStorage.setItem("Sypnosis", JSON.stringify(list));

          setloader(false);
        }
      },
      (error) => {
        setloader(true);
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  // Youtube
  const initialmainYoutubeState = {
    YoutubeBookTitle: "",
    YoutubeBookUrl: "",
  };

  // getting mainYoutube info from Firebase
  const [YoutubeState, YoutubeStateF] = useState([]);

  useEffect(() => {
    // setloader(true);
    const unsub = onSnapshot(
      collection(db, "Youtube Book"),

      (snapshot) => {
        let list;

        snapshot.docs.forEach((doc) => {
          list = { ...doc.data() };
        });

        if (!list || list.length === 0) {
          localStorage.setItem("Youtube", JSON.stringify({}));
        } else {
          YoutubeStateF(list);
          localStorage.setItem("Youtube", JSON.stringify(list));

          setloader(false);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  // Book PDF
  const initialmainPDFState = {
    pdfBookTitle: "",
    pdfBookImgUrl: "",
    pdfBookDocUrl: "",
  };

  // getting mainBook PDF info from Firebase
  const [BookPDFState, BookPDFStateF] = useState([]);

  // getting mainPDF info from Firebase

  useEffect(() => {
    // setloader(true);
    const unsub = onSnapshot(
      collection(db, "Main Book PDF"),

      (snapshot) => {
        let list;

        snapshot.docs.forEach((doc) => {
          list = { ...doc.data() };
        });

        if (!list || list.length === 0) {
          localStorage.setItem("BookPDF", JSON.stringify({}));
        } else {
          BookPDFStateF(list);
          localStorage.setItem("BookPDF", JSON.stringify(list));

          setloader(false);
        }

        // if (list) {
        //   BookPDFStateF(list);
        //   setloader(false);
        // }  else {
        //   setloader(true)
        // }
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  // About Page

  // About State
  const initialAboutState = {
    abouttitle: "",
    aboutTextSection: "",
    aboutImgUrl: "",
    aboutImgCaption: "",
    equianoTitle: "",
    equianoTitleTextSection: "",
    peopleTitle: "",
    peopleImgUrl: "",
    founderBio: "",
    collabTitle: "",
    collabTextSection: "",
    specialThanksTitle: "",
    specialThanksTextSection: "",
  };

  // About State
  const [aboutStateInContext, aboutStateInContextF] =
    useState(initialAboutState);

  // getting about info from Firebase

  useEffect(() => {
    // setloader(true);
    const unsub = onSnapshot(
      collection(db, "About Page"),

      (snapshot) => {
        let list;

        snapshot.docs.forEach((doc) => {
          list = { ...doc.data() };
        });

        if (!list || list.length === 0) {
          localStorage.setItem("about", JSON.stringify({}));
        } else {
          localStorage.setItem("about", JSON.stringify(list));
          aboutStateInContextF(list);
          // console.log(list);
          setloader(false);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  // console.log(loader);

  // Books

  const BooksinitialState = {
    BooksTitle: "",
    BookSummary: "",
    ImgUrl: "",
  };

  // getting Books info from Firebase
  const [BookFromContext, BookFromContextF] = useState([]);

  // getting Books info from Firebase

  useEffect(() => {
    // setloader(true);
    const unsub = onSnapshot(
      collection(db, "Books"),

      (snapshot) => {
        let list;

        snapshot.docs.forEach((doc) => {
          list = { ...doc.data() };
        });

        if (!list || list.length === 0) {
          // setloader(true);
        } else {
          BookFromContextF(list);
          setloader(false);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  // Podcast

  const podcastinitialState = {
    podcastTitle: "",
    description: "",
  };

  // getting Podcast info from Firebase
  const [PodcastFromContext, PodcastFromContextF] = useState([]);

  // getting Podcast info from Firebase

  useEffect(() => {
    // setloader(true);
    const unsub = onSnapshot(
      collection(db, "Podcast"),

      (snapshot) => {
        let list;

        snapshot.docs.forEach((doc) => {
          list = { ...doc.data() };
        });

        if (!list || list.length === 0) {
          // setloader(true);
        } else {
          PodcastFromContextF(list);
          setloader(false);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  // Homepage
  const homepageInitialState = {
    leftOblongCircleImgUrl: "",
    leftOblongCircleTextSection: "",
    rightOblongCircleImgUrl: "",
    rightOblongCircleTextSection: "",
    footerTextSection: "",
    centerCircleTextSection: "",
    centerCircleLink: "",
    footerYear: "",
  };

  // getting Homepage info from Firebase
  const [HomepageFromContext, HomepageFromContextF] =
    useState(homepageInitialState);

  // getting Homepage info from Firebase

  useEffect(() => {
    setloader(true);
    // console.log(loader);
    const unsub = onSnapshot(
      collection(db, "Homepage"),

      (snapshot) => {
        let list;

        snapshot.docs.forEach((doc) => {
          list = { ...doc.data() };
        });

        if (!list || list.length === 0) {
          localStorage.setItem("homepage", JSON.stringify({}));
        } else {
          HomepageFromContextF(list);
          localStorage.setItem("homepage", JSON.stringify(list));
          // console.log(list);
          // setloader(false);
          // console.log(loader);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  // get current book id
  const [bookid, bookidF] = useState(false);

  // get current podcast id
  const [podcastid, podcastidF] = useState(false);

  // get current blog id
  const [blogid, blogidF] = useState(false);

  // get current resource id
  const [resourceid, resourceidF] = useState(false);

  // get current blogdetails going to text editor
  const [blogdetailstoEditor, blogdetailstoEditorF] = useState(false);

  // get current resourcedetails going to text editor
  const [resourcedetailstoEditor, resourcedetailstoEditorF] = useState(false);

  return (
    <AppContext.Provider
      value={{
        user,
        setuser,
        handleLogout,
        navigate,
        loader,
        setloader,

        notification,
        notificationF,

        bloginitialState,
        blogsFromDB,
        blogsFromDBArranged,
        handleDeleteBlog,
        resourcesFromDB,
        resourceinitialState,

        resourcesFromDBArranged,
        handleDeleteResource,
        booksFromDB,
        booksFromDBF,
        booksFromDBArranged,
        handleDeleteBook,
        PodcastFromDB,
        PodcastFromDBArranged,
        handleDeletePodcast,

        initialmainSypnosisState,
        initialmainYoutubeState,
        initialmainPDFState,
        BooksinitialState,
        podcastinitialState,

        BookFromContext,
        SypnosisState,
        YoutubeState,
        BookPDFState,

        PodcastFromContext,

        initialAboutState,
        aboutStateInContext,

        bookid,
        bookidF,
        podcastid,
        podcastidF,
        blogid,
        blogidF,
        resourceid,
        resourceidF,

        blogdetailstoEditor,
        blogdetailstoEditorF,
        resourcedetailstoEditor,
        resourcedetailstoEditorF,

        HomepageFromContext,
        homepageInitialState,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
