// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBjWIePiEA4RCyK6pcgw66ygyleh4Ivkvw",
  authDomain: "afrogrids-72d84.firebaseapp.com",
  projectId: "afrogrids-72d84",
  storageBucket: "afrogrids-72d84.appspot.com",
  messagingSenderId: "41253417147",
  appId: "1:41253417147:web:89a5675ff3b773a94d799e",
  measurementId: "G-DGMJWFFJ0R",
};




// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const provider = new GoogleAuthProvider();
