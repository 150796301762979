/* eslint-disable no-unused-vars */
import { signInWithEmailAndPassword } from "firebase/auth";
import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useGlobalContext } from "../../Function/Context";
import Loader from "../../loader";
import { auth } from "../../Utils/Firebase";

const initialState = {
  email: "",
  password: "",
};

const AdminLogin = () => {
  // get items from Context API
  const { user, setloader, loader, navigate, notification, notificationF } =
    useGlobalContext();

  // check if user is already sign IN
  // useEffect(() => {
  //   if (user) {
  //     navigate("/admin");
  //   }
  // });

  const [state, setstate] = useState(initialState);

  const { email, password } = state;

  function handleChange(e) {
    setstate({ ...state, [e.target.name]: e.target.value });
  }

  const handleLogin = async (e) => {
    e.preventDefault();

    if (email && password) {
      setloader(true);
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;

          localStorage.setItem("isLoggedIn", user);

          notificationF("");

          navigate("/admin");

          setloader(false);
          return toast("You've successfully Signed In");
        })

        .catch((error) => {
          setloader(true);
          //const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorMessage);
          notificationF(errorMessage);
          setloader(false);
        });
    } else {
      return toast.error("All fields must be filled");
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="w-full min-h-[100vh] bg-gradient-to-r from-violet-400/20 flex justify-center items-center">
          <div className="w-[500px] p-8 bg-violet-50/50 rounded-lg shadow-lg border border-violet-300">
            <h1 className="w-full text-center text-[2rem]">Admin Login</h1>
            <div className="w-full">
              <form>
                <input
                  type="email"
                  required
                  onChange={handleChange}
                  value={email}
                  name="email"
                  placeholder="Email"
                  className="w-full pl-2 py-3 border-b border-b-violet-300 outline-violet-400"
                />
                <input
                  type="password"
                  required
                  onChange={handleChange}
                  value={password}
                  name="password"
                  placeholder="Password"
                  className="w-full pl-2 py-3 mt-5 border-b border-b-violet-300 outline-violet-400"
                />
                <p>{notification}</p>
                <button
                  onClick={handleLogin}
                  className="w-full py-3 mt-5 rounded-md bg-violet-400 hover:bg-violet-300 hover:translate-y-[6px] transition-all duration-300"
                >
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminLogin;
