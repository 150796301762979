/* eslint-disable no-unused-vars */
import React from "react";
import arc from "./images/Frame 7.png";
import Form from "./Form";
import { useState, useEffect } from "react";
import words from "./images/frame.png";
import afro from "./images/afrogrids.png";
import { Link } from "react-router-dom";
//import circleImg1 from "./images/circleImg1.png";
//import circleImg2 from "./images/circleImg2.png";
import { useGlobalContext } from "./admin/Function/Context";
import ScrollToTop from "./ScrollToTop";

// eslint-disable-next-line react/prop-types
const Main = ({ homeAnimation }) => {
  const { HomepageFromContext, homepageInitialState } = useGlobalContext();

  const homepageFromLocalStorage = JSON.parse(localStorage.getItem("homepage"));

  const [HomeState, HomeStateF] = useState(HomepageFromContext);
  // console.log(HomeState);

  useEffect(() => {
    if (HomepageFromContext === homepageInitialState) {
      HomeStateF(homepageFromLocalStorage);
    } else {
      HomeStateF(HomepageFromContext);
    }
  }, [HomepageFromContext]);

  const {
    leftOblongCircleImgUrl,
    leftOblongCircleTextSection,
    rightOblongCircleImgUrl,
    rightOblongCircleTextSection,
    centerCircleTextSection,
    centerCircleLink,
  } = HomeState;

  //to set hover state of hero img
  const [heroHover, setHeroHover] = useState(false);

  const handleHeroHover = () => {
    setHeroHover(true);
  };

  //to set hover state of hand img
  const [handHover, setHandHover] = useState(false);

  const handleHandHover = () => {
    setHandHover(true);
  };

  const handleMouseOut = () => {
    setHeroHover(false);
    setHandHover(false);
  };

  //to blank out section 2 until the home slide in ends
  //const [section2, setSection2] = useState(false);

  /*useEffect(() => {
    function wait() {
      setTimeout(() => {
        setSection2(true);
      }, 4000);
    }
    // Check if the page has already loaded
    if (document.readyState === "complete") {
      wait();
    } else {
      window.addEventListener("load", wait);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", wait);
    }
  }, []);*/

  return (
    <main className="w-full bg-[#47a3b3] mt-[-2px] sm:mt-0 font-phil">
      <section className="w-full h-[507px] sm:h-[1100px] md:h-[1150px] lg:h-[1400px] no-scrollbar relative">
        <section
          className={`w-full h-[507px] sm:h-[1100px] md:h-[1150px] lg:h-[1400px] relative z-[0] bg-cover bg-no-repeat bg-home no-scrollbar`}
        >
          <div className="w-full h-[507px] sm:h-[1100px] md:h-[1150px] lg:h-[1400px] overlay pt-[130px] sm:pt-[310px] z-[-1] flex justify-center items-start absolute top-[0px] left-0 bg-gradient-to-b from-[#47a3b3]/90 to-[#47a3b3]/80">
            <div className="w-full px-[40px] sm:px-0 relative translate-y-[40px] sm:translate-y-0">
              {/**the three bubble elements container */}
              <div
                className={`w-full  ${
                  !homeAnimation
                    ? "hidden"
                    : "block home-slide-mob sm:home-slide no-scrollbar"
                } mx-auto sm:w-[80%] md:w-[80%] lg:w-[70%] flex justify-center items-center translate-y-[-50px] sm:translate-y-[0px]`}
              >
                <div className="w-[100px] sm:w-[220px] md:w-[330px] h-[150px] sm:h-[290px] md:h-[360px] lg:h-[450px] pt-5 sm:pt-10 bg-gradient-to-b from-[#0d3942] to-[#010405] rounded-full relative">
                  <div
                    onMouseOver={handleHeroHover}
                    onClick={handleHeroHover}
                    onMouseOut={handleMouseOut}
                    className="overlay cursor-pointer text-[#ffd5aa] font-[700] text-[0.5rem] sm:text-[1rem] text-center absolute top-0 left-0 w-full h-full rounded-full z-[10]"
                  >
                    {heroHover && (
                      <div className="w-[80%] absolute bottom-[15px] sm:bottom-[30px] left-[50%] translate-x-[-50%]">
                        {/* <p>Bones of The Sea - </p>
                        <a href="h" className="underline">
                          African Folklore on Digital{" "}
                          {window.innerWidth >= 767 && <br />} Inequality
                        </a> */}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: leftOblongCircleTextSection,
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {!heroHover && (
                    <img
                      alt="African folklore undersea cables"
                      src={leftOblongCircleImgUrl}
                      className={`w-[75%] h-auto mx-auto relative right-[-10px]`}
                    />
                  )}
                  {heroHover && (
                    <img
                      alt="African folklore undersea cables"
                      src={leftOblongCircleImgUrl}
                      className={`w-[75%] h-auto relative sm:absolute top-[-25px] left-[-10px] sm:top-[-10px] sm:left-[-10px] hero--hover `}
                    />
                  )}
                </div>
                <div className="w-[105px] sm:w-[220px] md:w-[340px] h-[180px] sm:h-[290px] md:h-[360px] lg:h-[475px] text-white/80 scale-[1.1] sm:scale-[1.25] bg-[#10292f] flex justify-center items-center rounded-full relative">
                  <div className="w-full h-[36%] absolute top-0 left-0 rounded-t-full">
                    {/*<div className="w-[94%] h-full mx-auto text-[0.5rem] sm:text-[0.75rem] lg:text-[0.9rem] tracking-wider text-gray-400 relative rounded-t-full">
                      <p className="absolute bottom-0 rotate-[-85deg]">What</p>
                      <p className="absolute bottom-[28%] left-[4%] rotate-[-75deg]">
                        does
                      </p>
                      <p className="absolute bottom-[50%] left-[14%] rotate-[-60deg]">
                        a
                      </p>
                      <p className="absolute bottom-[68%] left-[20%] rotate-[-35deg]">
                        decol
                      </p>
                      <p className="absolute bottom-[78%] left-[32%] sm:left-[33%] rotate-[-18deg]">
                        onial
                      </p>
                      <p className="absolute bottom-[81%] left-[50%] rotate-[5deg]">
                        digi
                      </p>
                      <p className="absolute bottom-[78%] left-[59%] sm:left-[60%] rotate-[14deg]">
                        tal
                      </p>
                      <p className="absolute bottom-[66%] left-[72%] rotate-[37deg]">
                        fut
                      </p>
                      <p className="absolute bottom-[57%] left-[77%] rotate-[49deg]">
                        ure
                      </p>
                      <p className="absolute bottom-[32%] left-[83%] rotate-[65deg]">
                        look
                      </p>
                      <p className="absolute bottom-[5%] left-[85%] rotate-[89deg]">
                        like?
                      </p>
                  </div>*/}
                    <img
                      alt=""
                      src={words}
                      className="block w-[90%] absolute top-1 left-[4%] sm:left-[4%]"
                    />
                  </div>
                  <img alt="" src={afro} className="w-[75%]" />
                  <a href={`${centerCircleLink}`}>
                    <button className="w-[70%] text-[#0b3139] text-[0.4rem] sm:text-[0.55rem] lg:text-[1rem] bg-[#ffd5aa] font-[700] px-[5px] sm:px-2 md:px-[25px] py-[3px] sm:py-[12px] absolute left-[50%] bottom-[25px] sm:bottom-[35px] md:bottom-[45px] lg:bottom-[65px] translate-x-[-50%] rounded-[18px] lg:rounded-[28px] hover:scale-[1.1] transition-all duration-300">
                      {centerCircleTextSection}
                    </button>
                  </a>
                </div>
                <div
                  className={`w-[100px] sm:w-[220px] md:w-[330px] h-[150px] sm:h-[290px] md:h-[360px] lg:h-[450px] pt-5 sm:pt-10 border-t-white/50 border-t-[1px] sm:border-t-0 border-l-black/20 border-l-[1px] sm:border-l-white/20 sm:border-l-[1px] rounded-full relative`}
                >
                  <div
                    onMouseOver={handleHandHover}
                    onClick={handleHandHover}
                    onMouseOut={handleMouseOut}
                    className="overlay cursor-pointer text-white font-[700] text-[0.5rem] sm:text-[0.9rem] md:text-[1rem] text-center absolute top-0 left-0 w-full h-full rounded-full z-[10]"
                  >
                    {handHover && (
                      <div className="w-[80%] absolute bottom-[20px] sm:bottom-[35px] md:bottom-[55px] left-[50%] translate-x-[-50%]">
                        {/* <p>Equiano and 2Africa -</p>
                        <a href="h" className="underline">
                          Big Tech Undersea Cables
                          {window.innerWidth >= 767 && <br />} in Africa
                        </a> */}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: rightOblongCircleTextSection,
                          }}
                        />
                      </div>
                    )}
                    {!handHover && (
                      <img
                        alt="Undersea cable"
                        src={rightOblongCircleImgUrl}
                        className={`w-[75%] h-auto mx-auto relative right-0 sm:right-[-20px] bottom-[-20px] sm:bottom-[-80px]`}
                      />
                    )}
                    {handHover && (
                      <img
                        alt="Undersea cable"
                        src={rightOblongCircleImgUrl}
                        className={`w-[75%] h-auto relative sm:absolute top-[-5px] left-[-5px] sm:top-[10px] sm:left-[20px] hero--hover `}
                      />
                    )}
                  </div>
                  <img
                    alt=""
                    src={arc}
                    className=" hidden sm:block absolute bottom-0"
                  />
                </div>
              </div>
              {/**the three bubble elements container */}

              <div className="w-[250px] mx-auto flex items-center gap-[10px] translate-y-[100px] sm:translate-y-[260px] rotate-[90deg]">
                <div className="text-[0.7rem] sm:text-[1.3rem] relative">
                  SCROLL
                </div>
                <div className="w-[85px] h-[1px] sm:h-[2px] bg-[#0b3139] absolute translate-x-[50px] scroll--line--mob sm:scroll--line"></div>
              </div>
            </div>
          </div>
          <svg
            className="absolute bottom-[-20px] block z-[-1]"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 319"
          >
            <path
              fill="#b9e6e1"
              fillOpacity="1"
              d="M0,160L48,181.3C96,203,192,245,288,234.7C384,224,480,160,576,117.3C672,75,768,53,864,85.3C960,117,1056,203,1152,224C1248,245,1344,203,1392,181.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            ></path>
          </svg>
        </section>
      </section>

      <section
        className={`w-full bg-[#b9e6e1] h-[430px] pb-[30px] sm:mb-0 pt-[30px] sm:pt-0 mt-[0px] sm:mt-[20px] sm:h-[550px] lg:h-[480px] relative`}
      >
        <div
          className={`w-full bg-[#b9e6e1] h-[300px] overlay z-[1] flex justify-center items-center absolute`}
        >
          <div className="w-full sm:w-[510px] translate-y-[20px] sm:translate-y-[70px]  lg:translate-y-[0px] text-center">
            <h2
              className={`w-full md:w-[fit-content] mx-auto text-[1.25rem] bg-[#b9e6e1] md:bg-inherit ${
                heroHover && "scale-[1.2]"
              } ${
                handHover && "scale-[1.2]"
              } sm:text-[2.5rem] font-[700] mb-[40px]`}
            >
              Get in Touch
            </h2>
            <Form />
          </div>
        </div>
      </section>
      <ScrollToTop />
    </main>
  );
};
/**bg-cable-mob md:bg-cable  ${
                    handHover
                      ? "move--bg--mob2 md:move--bg2"
                      : "move--bg--mob md:move--bg"
                  } bg-[length:90px_130px] sm:bg-[length:250px_400px] bg-no-repeat */

export default Main;
