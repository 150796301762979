/* eslint-disable no-unused-vars */
import React from "react";
import { useState, useRef } from "react";

const Form = () => {
  const formRef = useRef(null);

  const scriptUrl =
    "https://script.google.com/macros/s/AKfycbwdwEQj6dZPd6TdclHweVq6JDUubVkBwOu0-wnt6QOwXUJAeNUTnxlRBCxkzGjgwIZi/exec";

  const [loading, setLoading] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    fetch(scriptUrl, { method: "POST", body: new FormData(formRef.current) })
      .then((res) => {
        console.log("SUCCESSFULLY SUBMITTED");
        setLoading(false);
        setSubmitted(true);
      })
      .catch((err) => console.log(err));
  };

  const handleSendAnother = () => {
    setSubmitted(false);
  };
  return (
    <div className="text-black px-[20px] sm:px-0 ">
      {!submitted && (
        <form
          method="post"
          ref={formRef}
          name="google-sheet"
          className="w-full"
          onSubmit={handleSubmit}
        >
          <div className="w-full flex mb-[45px]">
            <div className="w-1/2 text-left mx-3">
              <label
                htmlFor="firstname"
                className="text-[1.1rem] sm:text-[2.1rem]"
              >
                First name
              </label>
              <br />
              <input
                required
                type=""
                name="firstname"
                placeholder="First Name"
                className="w-full pt-[5px] bg-[#b9e6e1] placeholder:text-black border-b-[1px] border-b-[#0b3139] text-[0.8rem] outline-none"
              />
            </div>
            <div className="w-1/2 text-left mx-3">
              <label
                htmlFor="lastname"
                className="text-[1.1rem] sm:text-[2.1rem]"
              >
                Last name
              </label>
              <br />
              <input
                required
                type=""
                name="lastname"
                placeholder="Last Name"
                className="w-full pt-[5px] bg-[#b9e6e1] placeholder:text-black border-b-[1px] border-b-[#0b3139] text-[0.8rem] outline-none"
              />
            </div>
          </div>
          <div className="w-full flex mb-[45px]">
            <div className="w-1/2 text-left mx-3">
              <label htmlFor="email" className="text-[1.1rem] sm:text-[2.1rem]">
                Email address
              </label>
              <br />
              <input
                required
                type="email"
                name="email"
                placeholder="Insert your email address"
                className="w-full pt-[5px] bg-[#b9e6e1] placeholder:text-black border-b-[1px] border-b-[#0b3139] text-[0.8rem] outline-none"
              />
            </div>
            <div className="w-1/2 text-left mx-3">
              <label htmlFor="phone" className="text-[1.1rem] sm:text-[2.1rem]">
                Phone{" "}
              </label>
              <br />
              <input
                type="number"
                name="phone"
                placeholder="Optional - Telegram or Signal number"
                className="w-full pt-[5px] bg-[#b9e6e1] placeholder:text-black border-b-[1px] border-b-[#0b3139] text-[0.8rem] outline-none"
              />
            </div>
          </div>
          <div className="w-[97%] text-left mx-3">
            <label htmlFor="message" className="text-[1.1rem] sm:text-[2.1rem]">
              Message
            </label>
            <br />
            <input
              required
              type=""
              name="message"
              placeholder="Insert your full message here and we will get back to you soon"
              className="w-full pt-[5px] bg-[#b9e6e1] placeholder:text-black border-b-[1px] border-b-[#0b3139] text-[0.8rem] outline-none"
            />
          </div>
          <div className="form-style">
            <button className="cursor-pointer text-[0.9rem] md:text-[2.2rem] font-[700] px-[15px] md:px-[35px] py-[7px] md:py-[18px] mt-[40px] bg-[#ffd5aa] rounded-[20px] md:rounded-[35px] shadow-lg hover:scale-[1.1] transition-all duration-300">
              {loading ? "Sending..." : "Send Message"}
            </button>
            {/*<input
              type="submit"
              name="submit"
              value={loading ? "Sending..." : "Send Message"}
              className="cursor-pointer text-[0.9rem] md:text-[2.2rem] font-[700] px-[15px] md:px-[35px] py-[7px] md:py-[18px] mt-[40px] bg-[#ffd5aa] rounded-[20px] md:rounded-[35px] shadow-lg hover:scale-[1.1] transition-all duration-300"
      />*/}
          </div>
        </form>
      )}

      {submitted && (
        <div className="w-full h-full flex justify-center items-center">
          <div>
            <h3 className="text-[2rem]">
              Thank you for reaching out. We’ll get back to you soon.
            </h3>
            <button
              onClick={handleSendAnother}
              className="cursor-pointer text-[1.4rem] px-[20px] py-[10px] mt-[20px] bg-[#ffd5aa] rounded-2xl shadow-md hover:scale-[1.1] transition-all duration-300"
            >
              Send Another
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Form;
