/* eslint-disable*/
import React from "react";
import { useState } from "react";
import navHover from "./images/navHover.png";
import hamburger from "./images/icons8-menu-30 (1).png";
import close from "./images/icons8-close-50.png";
import facebook from "./images/icons8-facebook-30.png";
import linkedin from "./images/icons8-linkedin-30.png";
import youtube from "./images/icons8-youtube-squared-30.png";
import behance from "./images/icons8-behance-30.png";
import { Link } from "react-router-dom";
import logo from "./images/logo.png";
import { useGlobalContext } from "./admin/Function/Context";

const Header = (props) => {
  //to handle the state of nav links
  const [hover, setHover] = useState({
    home: false,
    about: false,
    book: false,
    blog: false,
  });

  //to handle hover of nav links
  function handleHover(event) {
    const { id } = event.target;
    setHover((prevState) => {
      return {
        ...prevState,
        [id]: true,
      };
    });
  }

  //to handle the mouse out of nav links
  function handleMouseOut() {
    setHover({
      home: false,
      about: false,
      book: false,
      blog: false,
    });
  }

  //to handle the link highlight of current page
  const [currentPage, setCurrentPage] = useState({
    home: false,
    about: false,
    book: false,
    blog: false,
  });

  //to handle click of nav links
  function handleCurrentPage(event) {
    const { id } = event.target;
    setCurrentPage((prevState) => {
      return {
        home: false,
        about: false,
        book: false,
        blog: false,
        [id]: true,
      };
    });
  }

  const [openMenu, setOpenMenu] = useState(false);
  function handleClick() {
    setOpenMenu((prevState) => !prevState);
  }
  const hide = {
    display: "none",
  };
  const show = {
    display: "block",
  };

  //to close the dropdown after clicking a link
  const hideDropdown = () => {
    setOpenMenu(false);
  };

  const { loader } = useGlobalContext();

  return (
    <header className={`font-phil text-black ${loader ? "hidden" : "block"}`}>
      {/*desktop header */}
      <div
        // eslint-disable-next-line react/prop-types
        style={props.style}
        className={`w-full sm:min-w-[700px] hidden z-[100] pt-[80px] sm:flex justify-center items-center`}
      >
        <div className="sm:w-[80%] w-[75%] h-[80px] flex items-center pl-[50px] pr-[35px] rounded-full border border-black">
          <div
            // eslint-disable-next-line react/prop-types
            style={props.logo}
            className="w-[120px] h-[120px] mr-auto rounded-full"
          >
            <img alt="logo" src={logo} className="w-full h-full" />
          </div>
          <nav className="flex sm:gap-[0px] gap-[0px] text-[1.3rem] lg:text-[1.85rem]">
            <Link to="/">
              <div
                className={`cursor-pointer w-[90px] lg:w-[135px] ${
                  // eslint-disable-next-line react/prop-types
                  currentPage?.home || props.currentPage?.home
                    ? "text-[#ffd5aa]"
                    : null
                } text-center hover:text-[#ffd5aa] relative`}
              >
                <div
                  id="home"
                  onClick={handleCurrentPage}
                  onMouseEnter={handleHover}
                  onMouseOut={handleMouseOut}
                  className="z-[50] overlay absolute w-full h-full"
                ></div>
                Home
                {hover.home && (
                  <img
                    alt=""
                    src={navHover}
                    className="nav--hover z-[20] w-[135px] absolute top-[-4px]"
                  />
                )}
                {currentPage?.home || props.currentPage?.home ? (
                  <img
                    alt=""
                    src={navHover}
                    className="nav--hover z-[20] w-[135px] absolute top-[-4px]"
                  />
                ) : null}
              </div>
            </Link>
            <Link to="/about">
              <div
                className={`cursor-pointer w-[90px] lg:w-[135px] ${
                  // eslint-disable-next-line react/prop-types
                  currentPage?.about || props.currentPage?.about
                    ? "text-[#ffd5aa]"
                    : null
                } text-center hover:text-[#ffd5aa] relative`}
              >
                <div
                  id="about"
                  onClick={handleCurrentPage}
                  onMouseEnter={handleHover}
                  onMouseOut={handleMouseOut}
                  className="z-[50] overlay absolute w-full h-full"
                ></div>
                About
                {hover.about && (
                  <img
                    alt=""
                    src={navHover}
                    className="nav--hover z-[20] w-[150px] absolute top-[-4px]"
                  />
                )}
                {currentPage?.about || props.currentPage?.about ? (
                  <img
                    alt=""
                    src={navHover}
                    className="nav--hover z-[20] w-[135px] absolute top-[-4px]"
                  />
                ) : null}
              </div>
            </Link>
            <Link to="/book">
              <div
                className={`cursor-pointer w-[90px] lg:w-[135px] ${
                  currentPage?.book || props.currentPage?.book
                    ? "text-[#ffd5aa]"
                    : null
                } text-center hover:text-[#ffd5aa] relative`}
              >
                <div
                  id="book"
                  onClick={handleCurrentPage}
                  onMouseEnter={handleHover}
                  onMouseOut={handleMouseOut}
                  className="z-[50] overlay absolute w-full h-full"
                ></div>
                Book
                {hover.book && (
                  <img
                    alt=""
                    src={navHover}
                    className="nav--hover z-[20] w-[135px] absolute top-[-4px]"
                  />
                )}
                {currentPage?.book || props.currentPage?.book ? (
                  <img
                    alt=""
                    src={navHover}
                    className="nav--hover z-[20] w-[135px] absolute top-[-4px]"
                  />
                ) : null}
              </div>
            </Link>
            <Link to="/blog">
              <div
                className={`cursor-pointer w-[90px] lg:w-[135px] ${
                  currentPage?.blog || props.currentPage?.blog
                    ? "text-[#ffd5aa]"
                    : null
                } text-center hover:text-[#ffd5aa] relative`}
              >
                <div
                  id="blog"
                  onClick={handleCurrentPage}
                  onMouseEnter={handleHover}
                  onMouseOut={handleMouseOut}
                  className="z-[50] overlay absolute w-full h-full"
                ></div>
                Blog
                {hover.blog && (
                  <img
                    alt=""
                    src={navHover}
                    className="nav--hover z-[20] w-[135px] absolute top-[-4px]"
                  />
                )}
                {currentPage?.blog || props.currentPage?.blog ? (
                  <img
                    alt=""
                    src={navHover}
                    className="nav--hover z-[20] w-[135px] absolute top-[-4px]"
                  />
                ) : null}
              </div>
            </Link>
          </nav>
        </div>
      </div>
      {/*desktop header */}

      {/*mobile header */}
      <div
        className={`sm:hidden w-full px-[20px] pt-[30px] absolute top-0 left-0 z-[100] border-b-[0px] border-b-[#47a3b3] flex justify-between`}
      >
        <Link to="/">
          <img
            alt="logo"
            src={logo}
            className="w-[80px] h-[80px] mr-auto translate-y-[-20px]"
          />
        </Link>
        <img
          alt="hamburger"
          src={hamburger}
          onClick={handleClick}
          className="w-[28px] h-[25px] cursor-pointer"
        />

        <div
          className="w-full h-[100vh] z-[200] bg-black/80 fixed top-0 left-0"
          style={openMenu ? show : hide}
        >
          <img
            className="w-[25px] h-[25px] cursor-pointer mr-[25px] absolute top-[30px] right-[10px]"
            alt=""
            src={close}
            onClick={handleClick}
          />
          <ul className="slide float-right w-[65%] h-full bg-[#47a3b3] px-[30px] text-[1.25rem] font-[700] text-[#1d2025] pt-[100px]">
            <li className="my-4">
              <Link to="/" onClick={hideDropdown}>
                <div className="w-full">Home</div>
              </Link>
            </li>
            <li className="my-4">
              <Link to="/about" onClick={hideDropdown}>
                <div className="w-full">About</div>
              </Link>
            </li>
            <li className="my-4">
              <Link to="/book" onClick={hideDropdown}>
                <div className="w-full">Book</div>
              </Link>
            </li>
            <li className="my-4">
              <Link to="/blog" onClick={hideDropdown}>
                <div className="w-full">Blog</div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/*mobile header */}
    </header>
  );
};

export default Header;
