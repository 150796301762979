import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Loader from "../loader";
// import Admin from "../Pages/Backend/Admin";
// import AdminAboutt from "../Pages/Backend/AdminAbout";
// import AdminBlogg from "../Pages/Backend/AdminBlog";
// import AdminBookk from "../Pages/Backend/AdminBook";
// import AdminHomee from "../Pages/Backend/AdminHome";
// import AdminLogin from "../Pages/Backend/AdminLogin";
// import AdminSetting from "../Pages/Backend/AdminSettings";
// import AboutPage from "../Pages/FrontEnd/AboutPage";
// import BlogDetailsPage from "../Pages/FrontEnd/BlogDetailsPage";
// import BlogPage from "../Pages/FrontEnd/BlogPage";
// import BookPage from "../Pages/FrontEnd/BookPage";
// import Homepage from "../Pages/FrontEnd/Homepage";
import PageNotFound from "../Pages/FrontEnd/PageNotFound";
import { useGlobalContext } from "./Context";

const Homepage = lazy(() => import("../Pages/FrontEnd/Homepage"));
const AboutPage = lazy(() => import("../Pages/FrontEnd/AboutPage"));
const BookPage = lazy(() => import("../Pages/FrontEnd/BookPage"));
const BlogPage = lazy(() => import("../Pages/FrontEnd/BlogPage"));
const BlogDetailsPage = lazy(() => import("../Pages/FrontEnd/BlogDetailsPage"));

const AdminLogin = lazy(() => import("../Pages/Backend/AdminLogin"));
const Admin = lazy(() => import("../Pages/Backend/Admin"));
const AdminHomee = lazy(() => import("../Pages/Backend/AdminHome"));
const AdminAboutt = lazy(() => import("../Pages/Backend/AdminAbout"));
const AdminBlogg = lazy(() => import("../Pages/Backend/AdminBlog"));
const AdminBookk = lazy(() => import("../Pages/Backend/AdminBook"));
const AdminSetting = lazy(() => import("../Pages/Backend/AdminSettings"));

const Pages = () => {
  // get user from Context API
  const { user } = useGlobalContext();

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/book" element={<BookPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blogs/:id" element={<BlogDetailsPage />} />

        <Route path="/login" element={<AdminLogin />} />

        {/* these set og pages  can only be accessed if user is  signed In */}

        {user && (
          <>
            <Route path="/admin" element={<Admin />} />
            <Route path="/admin/home" element={<AdminHomee />} />
            <Route path="/admin/about" element={<AdminAboutt />} />
            <Route path="/admin/blog" element={<AdminBlogg />} />
            <Route path="/admin/book" element={<AdminBookk />} />
            <Route path="/admin/settings" element={<AdminSetting />} />
          </>
        )}

        {/* user will be redirected to this page if they input invalid URL  */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
};

export default Pages;
