import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="w-full h-[100vh] flex justify-center items-center">
      <div className="w-[400px] p-4 rounded-lg shadow-lg border-2 border-violet-300">
        <div className="text-[2rem]">Oops! Page Not Found</div>
        <Link to="/">
          <button className="px-5 py-2 mt-4 rounded-md text-white bg-violet-400 hover:bg-violet-300 hover:translate-y-[6px] transition-all duration-300">
            Go back
          </button>
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
