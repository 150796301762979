/* eslint-disable*/
import React from "react";
import { useState, useRef } from "react";

const Newsform = ({ openNews, hidePopup, close }) => {
  const formRef = useRef(null);

  const scriptUrl =
    "https://script.google.com/macros/s/AKfycbwUSCuWuf5eVKFQJzKXte6MHu0-n-wxuR4kg3sYlC06K2pVtpitDPHYRLB27co0QoBe/exec";

  const [loading, setLoading] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    fetch(scriptUrl, { method: "POST", body: new FormData(formRef.current) })
      .then((res) => {
        console.log("SUCCESSFULLY SUBMITTED");
        setLoading(false);
        setSubmitted(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      className={`w-[90%] sm:w-[350px] slide ${
        openNews ? "block" : "hidden"
      } bg-[#b9e6e1] border-2 border-[#72bec4] p-4 rounded-lg shadow-xl fixed bottom-[150px] right-6 z-[100]`}
    >
      <img
        onClick={hidePopup}
        alt=""
        src={close}
        className="w-5 h-5 float-right mb-4 cursor-pointer"
      />
      {!submitted && (
        <form
          method="post"
          ref={formRef}
          name="google-sheet"
          className="w-full"
          onSubmit={handleSubmit}
        >
          <label htmlFor="email" className="text-[1rem] sm:text-[1.4rem]">
            Sign up for Our Newsletter
          </label>

          <input
            required
            type="email"
            name="email"
            placeholder="Insert your email address"
            className="w-full py-[5px] mt-2 bg-[#b9e6e1] placeholder:text-black border-b-[1px] border-b-[#0b3139] text-[0.8rem] outline-none"
          />
          <button className="cursor-pointer text-[0.9rem] md:text-[1rem] font-[700] px-[15px] md:px-[35px] py-[7px] md:py-[12px] mt-[20px] bg-[#ffd5aa] rounded-[20px] md:rounded-[18px] shadow-lg hover:scale-[1.1] transition-all duration-300">
            {loading ? "Signing up..." : "Sign Up"}
          </button>
        </form>
      )}

      {submitted && (
        <div className="w-full text-center text-[1rem] sm:text-[1.4rem]">
          <p>You have successfully subscribed for our Newsletter!</p>
        </div>
      )}
    </div>
  );
};

export default Newsform;
