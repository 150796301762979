import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import Main from "./Main";
import "./output.css";
import { Routes, Route } from "react-router-dom";
// import About from "./About";
// import Book from "./Book";
// import Blog from "./Blog";
// import BlogDetails from "./BlogDetails";
import { useState, useEffect } from "react";
// import Dashboard from "./admin/Dashboard";
// import AdminNavbar from "./admin/AdminNavbar";
// import Sidebar from "./admin/Sidebar";
// import AdminAbout from "./admin/Components/AdminAbout";
// import AdminBook from "./admin/AdminBook";
// import AdminBlog from "./admin/AdminBlog";
// import AdminSettings from "./admin/AdminSettings";
// import toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminLogin from "./admin/Pages/Backend/AdminLogin";
import { useGlobalContext } from "./admin/Function/Context";
import Pages from "./admin/Function/Pages";

function App() {
  // get user from context API
  const { user } = useGlobalContext();

  const [homeAnimation, setHomeAnimation] = useState(false);

  useEffect(() => {
    function onPageLoad() {
      setHomeAnimation(true);
    }
    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  //to handle the header style of the about page
  const headerAbout = {
    position: "relative",
    paddingBottom: "70px",
    background: "linear-gradient(0deg, #a1d9d7, #5eb2bc)",
  };

  //to handle the header style of the other pages
  const headerHome = {
    position: "absolute",
    top: "0",
    left: "0",
    paddingBottom: "0",
    opacity: `${homeAnimation ? "1" : "0"}`,
  };

  //to handle the header style of the other pages
  const headerRest = {
    position: "absolute",
    top: "0",
    left: "0",
    paddingBottom: "0",
  };

  //logo style in home page
  const logoHome = {
    backgroundColor: "#429bac",
  };
  //logo style in about page
  const logoAbout = {
    background: "linear-gradient(#72bec4, #91d0d0)",
  };
  //logo style in book page
  const logoBook = {
    backgroundColor: "#4ba7b4",
  };
  //logo style in blog page
  const logoBlog = {
    backgroundColor: "#4ba3b3",
  };

  return (
    <div className="App">
      <ToastContainer position="top-center" />

      <Pages
        user={user}
        logoHome={logoHome}
        logoAbout={logoAbout}
        logoBlog={logoBlog}
        logoBook={logoBook}
        headerAbout={headerAbout}
        headerHome={headerHome}
        headerRest={headerRest}
        homeAnimation={homeAnimation}
        AdminLogin={AdminLogin}
        Route={Route}
        Routes={Routes}
        Header={Header}
        Main={Main}
        Footer={Footer}
      />
    </div>
  );
}

export default App;
