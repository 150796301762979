/* eslint-disable no-unused-vars */
import React from "react";
import twitter from "./images/icons8-twitter-squared-50.png";
import youtube from "./images/icons8-youtube-squared-30.png";
import globe from "./images/icons8-globe-50.png";
import arrow from "./images/icons8-up-right-arrow-48.png";
import close from "./images/icons8-close-50.png";
import { useState } from "react";
import Newsform from "./Newsform";
import { useGlobalContext } from "./admin/Function/Context";

const Footer = () => {
  const { HomepageFromContext } = useGlobalContext();

  // About State
  const [HomeState, HomeStateF] = useState(HomepageFromContext);

  const { footerTextSection, footerYear } = HomeState;

  const [openNews, setOpenNews] = useState(false);
  function handleClick() {
    setOpenNews((prevState) => !prevState);
  }

  //to close the pop up
  const hidePopup = () => {
    setOpenNews(false);
  };

  return (
    <footer className="w-full pb-0 lg:pb-[0px] md:pb-0 bg-[#ffd5aa] flex justify-between font-phil border-t-gray-700/30 border-t-[1px]">
      <div className="w-[28%] md:w-[35%] lg:w-[20%] px-1 sm:px-[10px] py-6 flex justify-center items-center border-b border-b-gray-700/30 text-center border border-t-[#ffd5aa] border-b-[#ffd5aa] border-l-[#ffd5aa] border-r-gray-700/30">
        <div>
          <h2 className="text-[0.65rem] sm:text-[1rem] font-[700] mb-[10px]">
            Learn more about the artist and related work.
          </h2>
          <div className="w-full justify-center items-center flex gap-2">
            <a
              href="http://www.hadassahlouis.com"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-[15px] lg:w-[18px] h-[15px] lg:h-[18px] cursor-pointer hover:scale-150 transition-all duration-300"
                alt=""
                src={globe}
              />
            </a>
            <a
              href="http://www.twitter.com/hadassahlouis"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-[15px] lg:w-[20px] h-[15px] lg:h-[20px] cursor-pointer hover:scale-150 transition-all duration-300"
                alt=""
                src={twitter}
              />
            </a>
            <a
              href="https://www.youtube.com/hadassahlouis"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-[15px] lg:w-[20px] h-[15px] lg:h-[20px] cursor-pointer hover:scale-150 transition-all duration-300"
                alt=""
                src={youtube}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="text-center w-[44%] lg:w-[60%]">
        <div className="px-[4px] md:px-[20px] text-[0.65rem] sm:text-[0.95rem] pt-6 pb-3 sm:py-4 border border-t-[#ffd5aa] border-r-[#ffd5aa] border-l-[#ffd5aa] border-b-gray-700/30">
          {/* <h2 className="text-[0.65rem] sm:text-[0.95rem] mb-2 font-[700]">
            Afrogrids Open Licence
          </h2>
          <p className="text-[0.65rem] sm:text-[0.85rem]">
            Afro-Grids is a project in the{" "}
            <a
              href="https://foundation.mozilla.org/en/blog/10-projects-rethinking-data-stewardship-announcing-mozillas-latest-creative-media-awards/"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              2022 Mozilla Creative Media Award
            </a>
            . It is created by Esther Mwema.
          </p> */}
          <div dangerouslySetInnerHTML={{ __html: footerTextSection }} />
        </div>

        <div className="text-[0.65rem] sm:text-[0.85rem] my-2 sm:mt-2">
          {/* 2022 AfroGrids{" "}
          <a
            href="https://creativecommons.org/licenses/by/4.0/"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            CC-BY-4.0
          </a> */}
          <div dangerouslySetInnerHTML={{ __html: footerYear }} />
        </div>
      </div>
      <div className="w-[28%] md:w-[35%] lg:w-[20%] px-0 sm:px-[30px] py-6 flex justify-center items-center text-center border border-t-[#ffd5aa] border-b-[#ffd5aa] border-r-[#ffd5aa] border-l-gray-700/30">
        <div>
          <h2 className="text-[0.65rem] sm:text-[1rem] font-[700]">
            Sign Up For Our Newsletter
          </h2>
          <p
            onClick={handleClick}
            className="text-[0.65rem] sm:text-[0.95rem] font-[700] underline cursor-pointer flex justify-center"
          >
            Sign Up now <img alt="" src={arrow} className="w-[20px] h-[20px]" />
          </p>
        </div>
      </div>
      <Newsform close={close} openNews={openNews} hidePopup={hidePopup} />
    </footer>
  );
};

export default Footer;
